<template>
  <div
    class="py-10 md:py-20 px-4 lg:px-0"
    :class="component.wrapper"
  >
    <atoms-card
      class="mx-auto p-6 pb-8 md:p-10 lg:pb-12"
      :class="[
        maxWidth,
        component.borderClass
      ]"
      :rounded="component.rounded"
      :shadow="component.shadow"
      size="default"
    >
      <p
        v-if="$slots.title && !inGuides"
        class="font-bold mb-4"
        :class="titleClass"
      >
        <slot name="title">
          Things you should know
        </slot>
      </p>

      <div
        v-if="$slots.description"
        class="text-base md:text-lg md:leading-[1.625rem] [&>p]:mb-6 [&>p:last-child]:mb-0"
      >
        <slot name="description"></slot>
      </div>
    </atoms-card>
  </div>
</template>

<script setup>
defineOptions({
  name: 'MoleculesHomeFooterInfo'
})

const props = defineProps({
  theme: {
    type: String,
    default: 'gray-boxed',
    validator: value => ['gray-boxed', 'white-boxed', 'blue-boxed', 'default'].includes(value)
  },

  maxWidth: {
    type: String,
    default: 'max-w-wrapper-md'
  },

  containerClass: {
    type: String,
    default: 'lg:py-[5.5rem]'
  },

  titleClass: {
    type: String,
    default: 'text-2xl md:text-2.5xl'
  },

  inGuides: {
    type: Boolean,
    default: false
  }
})

const component = computed(() => {
  const wrapper = getKey(props.theme, {
    default: 'bg-white',
    'gray-boxed': 'bg-gray-10',
    'blue-boxed': 'bg-blue-10'
  })

  const rounded = ['gray-boxed', 'white-boxed', 'blue-boxed'].includes(props.theme)
    ? 'standard'
    : 'md'

  const borderClass = ['gray-boxed', 'white-boxed', 'blue-boxed'].includes(props.theme)
    ? 'border-gray-400 border-solid border'
    : ''

  const shadow = ['gray-boxed', 'white-boxed', 'blue-boxed'].includes(props.theme)
    ? 'none'
    : 'standard'

  return {
    wrapper,
    rounded,
    borderClass,
    shadow
  }
})
</script>
